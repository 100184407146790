import React from "react"
import useRoutePath from "../../../hooks/useRoutePath"
import { slugify } from "../../../utils"
import BackgroundMedia, { BackgroundMediaProps } from "../BackgroundMedia"
import HighlightBlock, { HighlightBlockLayout } from "./HighlightBlock"
//import * as styles from "./HighlightToPageBlock.module.scss"

type HighlightToPageBlockProps = {
  text?: string | null
  title?: string | null
  media?: Queries.DatoMediaFragment | null // TODO pass a ReactElement instead
  route?: {
    originalId?: string | null
  } | null
  urlParams?: string | null
  layout?: string | null
}

const HighlightToPageBlock = (props: HighlightToPageBlockProps) => {
  //console.log('Rendering HighlightToPageBlock', props)
  const getRoutePath = useRoutePath()
  const routePath = getRoutePath(props.route?.originalId)

  return (
    <HighlightBlock
      linkTo={routePath + (props.urlParams || "")}
      id={slugify(props.title)}
      layout={props.layout as HighlightBlockLayout}
      media={<BackgroundMedia {...(props.media as BackgroundMediaProps)} />}
      title={props.title}
      titleAs="h2"
      content={props.text && <p>{props.text}</p>}
    />
  )
}

export default HighlightToPageBlock
