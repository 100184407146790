import { graphql } from "gatsby"
import React from "react"
import HighlightToPageBlock from "../../molecules/tileable-blocks/HighlightToPageBlock"
//import * as styles from "./DatoHighlightToPage.module.scss"

const DatoHighlightToPage = (props: Queries.DatoHighlightToPageFragment) => {
  // console.debug('Rendering DatoHighlightToPage', props)
  return <HighlightToPageBlock {...props} />
}

export default DatoHighlightToPage

export const query = graphql`
  fragment DatoHighlightToPage on DatoCmsHighlightToPage {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    text
    title
    media {
      ...DatoMedia
    }
    route {
      originalId
    }
    urlParams
    layout
  }
`
